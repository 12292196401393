:root {
  --site-announcement-height: 0px;
}

._preload * {
  transition: none !important;
}

.skip-link {
  @apply absolute overflow-hidden text-sm;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);

  &:focus {
    @apply h-auto w-auto bg-white px-3 py-2 text-black;
    clip: auto;
    z-index: 10000;
  }
}

.is-filtered-loading {
  @apply relative blur-sm;
}

.site-announcement-bar {
  @apply relative;
  margin-top: calc(-1 * var(--site-announcement-height));
}

body {
  margin-top: var(--site-announcement-height);
}
