@import 'tailwindcss/base';

@import 'base/global';
@import 'base/layout';
@import 'base/typography';

@import 'tailwindcss/components';

@import 'components/button';
@import 'components/components';
@import 'components/dropdown';
@import 'components/form';
@import 'components/mobile-bar';
@import 'components/modal';
@import 'components/page-header';
@import 'components/site-header';
@import 'components/site-widget';
@import 'components/slide-toggle';
@import 'components/slider';

@import 'elements/lists';
@import 'elements/list-slider';
@import 'elements/testimonials';

@import 'sections/home';

@import 'tailwindcss/utilities';
