.site-widget {
  @apply fixed bottom-5 right-1 z-20 w-full rounded-md border py-3 px-4 shadow-lg md:right-5;
  max-width: 250px;
  transform: translate3d(0, 200%, 0);

  @screen md {
    max-width: 300px;
  }

  &:not(.no-animate) {
    transition: all 0.5s ease-in-out;
  }

  .site-widget-top .for-expanded,
  .site-widget-top .for-collapsed {
    @apply hidden;
  }

  &[aria-expanded='true'] {
    background: white !important;
    color: black !important;

    .site-widget-top .for-expanded {
      @apply block;
    }

    .site-widget-items {
      @apply opacity-100;
    }
  }

  &[aria-expanded='false'] {
    .site-widget-top .for-collapsed {
      @apply block;
    }

    .site-widget-top .site-widget-title {
      @apply opacity-100;
    }

    .site-widget-items {
      @apply opacity-0;
    }
  }
}
