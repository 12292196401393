.btn {
  @apply inline-flex justify-center rounded-sm rounded-tl-2xl rounded-br-2xl px-8 py-3 text-center font-semibold transition-colors lg:text-lg;
}

.btn-text {
  @apply font-headline inline-flex font-black transition-colors lg:text-lg;
}

.btn-text-blue {
  @apply text-blue hover:text-green;
}

@layer components {
  .btn-fill-blue {
    @apply text-white;
    background: linear-gradient(336.79deg, #0d6c9d 5.5%, #0694de 84.93%);
    box-shadow: 1px 5px 8px rgba(19, 87, 150, 0.2);
    transition: background 0.2s ease-in-out;

    &:hover {
      background: linear-gradient(0deg, theme('colors.green.DEFAULT') 0%, theme('colors.green.DEFAULT') 100%);
    }
  }
}

.btn-fill-white {
  @apply text-blue hover:bg-green bg-white hover:text-white;
}
