[data-slide-toggle][aria-expanded='false'] {
  .for-collapsed {
    @apply block;
  }

  .for-expanded {
    @apply hidden;
  }
}

[data-slide-toggle][aria-expanded='true'] {
  .for-collapsed {
    @apply hidden;
  }

  .for-expanded {
    @apply block;
  }

  &.hide-on-expand {
    @apply pointer-events-none invisible;
  }
}

.is-sliding {
  @apply !overflow-hidden;
}
