:root {
  --container-width: 1690px;
  --container-sm-width: 1340px;
  --container-padding: 16px;

  @screen md {
    --container-padding: 4vw;
  }

  @screen xl {
    --container-padding: 75px;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-width) + (2 * var(--container-padding)));
}

.container-sm {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-sm-width) + (2 * var(--container-padding)));
}
