.type-cursor {
  @apply bg-blue inline-block w-0.5;
}

.type-cursor-blink {
  animation: blink 0.8s ease-in-out infinite;
}

@keyframes blink {
  0%,
  100% {
    background: theme('colors.blue.DEFAULT');
  }
  40%,
  50% {
    background: transparent;
  }
}
