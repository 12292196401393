body {
  --site-nav-fg: theme('colors.white');

  &.pagetype-homepage {
    --site-nav-fg: theme('colors.blue.dark');
  }

  &.has-page-header-images {
    @screen sm-down {
      --site-nav-fg: theme('colors.blue.dark');
    }
  }
}

.js-site-menu-open {
  @apply overflow-hidden;
}

.site-header {
  @apply sticky -top-[1px] z-20 bg-transparent py-6 lg:py-8;

  &.is-stuck {
    @apply border-gray-light border-b bg-white;

    .site-menu-open {
      @apply text-blue-dark;
    }

    .site-menu-primary .btn {
      @apply btn-fill-blue;
    }
  }
}

body:not(.has-page-header-images) .site-header:not(.is-stuck) {
  .site-logo {
    svg path {
      fill: theme('colors.white');
    }
  }
}

.pagetype-homepage .site-header:not(.is-stuck) {
  @screen md {
    .site-logo {
      svg path {
        fill: theme('colors.white');
      }
    }
  }
}

.pagetype-homepage {
  .site-menu-primary .btn {
    @apply btn-fill-blue;
  }
}

.site-menu-open {
  color: var(--site-nav-fg);
}

.site-menu {
  @apply bg-blue md-down:overflow-scroll-touch md-down:overflow-y-auto fixed top-0 right-0 isolate z-40 h-screen w-full translate-x-full transform-gpu px-6 pt-12 pb-48 transition-transform duration-300 md:max-w-[320px] lg:relative lg:h-auto lg:w-auto lg:max-w-none lg:translate-x-0 lg:bg-transparent lg:p-0 lg:transition-none;

  .js-site-menu-open & {
    @apply translate-x-0 transform-gpu shadow;
  }
}

.site-menu-screen {
  @apply pointer-events-none fixed inset-0 z-20 opacity-0 transition-opacity;

  &:before {
    @apply absolute inset-0 z-20 bg-white bg-opacity-50;
    content: '';
  }

  .js-site-menu-open & {
    @apply pointer-events-auto opacity-100;
  }
}

.site-menu-primary {
  @apply md-down:text-center max-w-[700px] space-y-4 lg:-mr-6 lg:flex lg:items-center lg:justify-between lg:space-y-0 lg:pl-6 lg:text-xs xl:mr-0 xl:pl-12 xl:text-sm 2xl:pl-16;

  a {
    @apply md-down:text-xl md-down:text-white lg:block;

    br {
      @apply md-down:hidden;
    }
  }

  & > li > a {
    @apply lg:transition-colors lg:hover:text-[#030507] lg:hover:opacity-100;
  }
}

.pagetype-homepage {
  .site-menu-primary > li > a {
    @apply lg:transition-colors lg:hover:text-[#609a3f] lg:hover:opacity-100;
  }
}

.site-header:not(.is-stuck) .site-menu-primary {
  @screen lg {
    color: var(--site-nav-fg);
  }
}
