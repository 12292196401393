@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=d47d0a41-9b45-4146-be7c-0d018f8c9fe1');

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('@fonts/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2') format('woff2'),
    url('@fonts/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('@fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2') format('woff2'),
    url('@fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('@fonts/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2') format('woff2'),
    url('@fonts/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff') format('woff');
}

@layer base {
  address {
    @apply not-italic;
  }

  strong {
    @apply font-bold;
  }
}

.text-body {
  @apply text-base lg:text-lg xl:text-[22px] xl:leading-relaxed;
}

.h0 {
  font-size: clamp(48px, 13vw, 200px);
  line-height: 1;
}

.h1 {
  font-size: clamp(28px, 3.75vw, 64px);
  line-height: 1.125;
}

.h2 {
  font-size: clamp(28px, 3.75vw, 55px);
  line-height: 1.125;
}

.h3 {
  font-size: clamp(24px, 2.5vw, 42px);
  line-height: 1.2;
}

.h4 {
  font-size: clamp(18px, 2.5vw, 32px);
  line-height: 1.3;
}

.em-italic {
  strong {
    @apply italic;
  }
}

.em-green {
  strong {
    @apply text-green;
  }
}

.richtext {
  p {
    @apply my-6;
  }

  &.no-first-margin p {
    margin-top: 0;
  }

  &.no-margin p {
    margin: 0;
  }

  &.no-last-margin p:last-child {
    margin-bottom: 0;
  }
}

.wysiwyg {
  strong {
    @apply font-semibold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold uppercase;

    &:not(:first-child) {
      @apply mt-[1.5em];
    }
  }

  h1,
  h2 {
    @apply text-2xl lg:text-3xl xl:text-4xl;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-lg lg:text-2xl;
  }

  p + h2,
  p + h3,
  p + h4,
  h2 + h3 {
    @apply mt-[1.5em];
  }

  hr {
    @apply my-8 lg:my-12;
  }

  ul:not([class]) {
    @apply my-6 list-inside list-disc;
  }

  ol:not([class]) {
    @apply my-6 list-inside list-decimal;
  }

  a {
    @apply font-medium underline transition-opacity hover:opacity-70;
  }
}

.wysiwyg-article {
  .ss-htmleditorfield-file.image {
    @apply w-full;
  }

  .ss-htmleditorfield-file.image.center,
  img.center,
  img.aligncenter {
    @apply w-full;
  }

  .ss-htmleditorfield-file.image.left,
  img.left,
  img.alignleft {
    @apply md:float-left md:mb-6 md:mr-6 md:w-1/2 xl:mr-10;
  }

  .ss-htmleditorfield-file.image.right,
  img.right,
  img.alignright {
    @apply md:float-right md:mb-6 md:ml-6 md:w-1/2 xl:ml-10;
  }

  .ss-htmleditorfield-file.embed {
    @apply relative;

    width: 100% !important;

    &:after {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .ss-htmleditorfield-file.embed.left {
    @apply md:float-left md:mb-6 md:mr-6 xl:mr-10;

    @screen md {
      width: 50% !important;
    }
  }

  .ss-htmleditorfield-file.embed.right {
    @apply md:float-right md:mb-6 md:ml-6 xl:ml-10;

    @screen md {
      width: 50% !important;
    }
  }

  .captionImage {
    @apply my-8 lg:my-16;
    width: 100% !important;
  }

  .caption {
    @apply text-sm text-gray-500;
  }

  blockquote {
    @apply font-headline text-blue relative my-8 text-xl font-black md:px-8 md:text-2xl lg:my-16 lg:px-16 lg:text-3xl lg:leading-snug xl:px-24;

    &:before {
      @apply mb-4 block h-8 w-8 lg:h-10 lg:w-10;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.75 12c-2.906 0-5.25 2.438-5.25 5.25a5.241 5.241 0 0 0 5.25 5.25h.75v.75c0 1.313-1.031 2.25-2.25 2.25-1.313 0-2.25 1.031-2.25 2.25 0 1.313.938 2.25 2.25 2.25 3.656 0 6.75-3 6.75-6.75v-6c0-2.813-2.438-5.25-5.25-5.25Zm-13.5 0C11.344 12 9 14.438 9 17.25a5.241 5.241 0 0 0 5.25 5.25H15v.75c0 1.313-1.031 2.25-2.25 2.25-1.313 0-2.25 1.031-2.25 2.25 0 1.313.938 2.25 2.25 2.25 3.656 0 6.75-3 6.75-6.75v-6c0-2.813-2.438-5.25-5.25-5.25ZM36 0H6C2.625 0 0 2.719 0 6v30c0 3.375 2.625 6 6 6h30c3.281 0 6-2.625 6-6V6c0-3.281-2.719-6-6-6Zm1.5 36c0 .844-.75 1.5-1.5 1.5H6A1.48 1.48 0 0 1 4.5 36V6c0-.75.656-1.5 1.5-1.5h30c.75 0 1.5.75 1.5 1.5v30Z' fill='%234A9C2E'/%3E%3C/svg%3E");
    }
  }
}

.wysiwyg-announcement {
  a {
    @apply underline transition-opacity hover:opacity-70;
  }
}
