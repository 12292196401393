.slider-pagination {
  @apply flex cursor-pointer gap-4 transition-colors;
}

.swiper-pagination-bullet {
  @apply bg-blue h-[6px] flex-1 bg-opacity-20 transition-colors hover:bg-opacity-40;
}

.swiper-pagination-bullet-active {
  @apply bg-green bg-opacity-100;
}
